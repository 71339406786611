.Summary-page
  width: calc(100%- 50px)
  margin-left 50px
  display: inline-block
  background #272727
  color white
  h1.ui.header
    padding-top .1em
    color white
  .Summary-card
    position relative
    margin 1em 0 1em 0
    background white
    color black
    padding-bottom 1em
    .missing
      color lightRed
    header
      height 3em
      line-height 3em
      padding-left 1em
      display flex
      h4
        margin auto
        flex-grow 3
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      a
        height 100%
        flex-basis: 3em
        flex-shrink 0
        text-align center
        background rodinRed
        .custom-write
          background url('/survey/img/write.svg')
          font-size 1.5em
          margin-top 10px
          margin-left 5px
  .Summary-answer-container
    .Summary-question
      margin-bottom 0
    .Summary-answer
      font-size 1.2em
