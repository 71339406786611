.Likert, .Boolean
  padding 1em 0
  .checked
    background lightGrey
  div
    display flex
    flex-flow row wrap
    justify-content space-around
  label
    border: 1px solid lightGrey
    padding 1em
    flex-grow 1
    text-align center

    &[data-scale="Agreement (Disagree to Agree)"]
      @media (max-width: 995px)
        width 100%
    
    &[data-scale="Importance (Not important to Extremely important)"]
      @media (max-width: 995px)
        width 100%
    
    &[data-scale="Strength (Weak to Very strong)"]
      @media (max-width: 555px)
        width 100%
    
    &[data-scale="Readiness (Not ready to Completely ready)"]
      @media (max-width: 670px)
        width 100%
    
    &[data-scale="Establishment (Minor usage to Fully Functioning"]
      @media (max-width: 995px)
        width 100%
    
    &[data-scale="Confidence (Not at all confident to Very confident"]
      @media (max-width: 730px)
        width 100%
    
    &[data-scale="Openness (Not open to Very open)"]
      @media (max-width: 615px)
        width 100%
    
  input
    display none