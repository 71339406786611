.GeoSelect
  position relative
  .checkmark
    position absolute
    right 10px
    top 46px

/**
 * The geosuggest module
 */
.geosuggest
  font-size: 18px;
  font-size: 1rem;
  position: relative;
  margin: 1em auto;
  text-align: left;

.GeoSelect .geosuggest input.geosuggest__input
  width: 100%;
  padding-right: 2.1em

.geosuggest__suggests
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 25em;
  padding: 0;
  margin-top: -2px;
  border 1px solid #85b7d9
  border-top: 0
  border-bottom: 0
  background: #fff;
  overflow-x: hidden;
  overflow-y: auto;
  list-style: none;
  z-index: 5;
  border-radius 3px

.geosuggest__suggests--hidden
  max-height: 0;
  overflow: hidden;
  border-width: 0;


/**
 * A geosuggest item
 */
.geosuggest-item
  font-size: 18px;
  font-size: 1rem;
  padding: .5em .65em;
  cursor: pointer;
  &:last-child
    border-bottom: 1px solid #85b7d9
    border-radius 3px


.geosuggest-item:hover,
.geosuggest-item:focus
  background: #f5f5f5;

.geosuggest-item--active
  background: #267dc0;
  color: #fff;

.geosuggest-item--active:hover,
.geosuggest-item--active:focus
  background: #ccc;
