.Likert, .Boolean
  padding 1em 0
  .checked
    background lightGrey
  div
    display flex
    flex-flow row wrap
    justify-content space-around
  label
    border: 1px solid lightGrey
    padding 1em
    flex-grow 1
    text-align center
  input
    display none