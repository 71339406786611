.StartPage
    height 100vh
    width calc(100%- 50px)
    margin-left 50px
    display: inline-block
    padding-top 20px

    background url("/survey/img/bg.png") bottom center no-repeat
    background-size 50% auto

    @media (max-width: 1079px)
        background-size 80% auto

    img
      height auto
      width 200px

    header
      height 3em
      text-align left
    
    footer
      position absolute
      text-align center
      bottom 0
      left 50px
      padding 1em 0 0.5em 0.5em
      background #fff
      width calc(100% - 50px)
      border-top 1px solid #eee

    .ui
      &.header
        margin-top 0

    .ui
      &.button
        background rodinRed
        width 100%
        color white

    .ui
      &.header
        text-transport uppercase
      
    .ui
      &.text
        font-size 0.9em