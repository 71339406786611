.btn-group
  padding-top 1em
  padding-bottom 2em
  margin 0 auto
  text-align center
  max-width 300px
  display flex
  align-items center
  justify-content space-betweeen
  .continue, .continue:hover,.continue:focus
    background rodinRed
    color white
    margin-right 0  
  a
    color: black
    flex-grow 1
    text-align left

  .ui.button
    width: auto
    flex-grow 2
