liBoxHeight = 50px

.Main-Navigation
  position absolute
  left 0
  z-index 10
  height 100%
  background lightGrey
  min-width liBoxHeight
  .number-link
    position relative
    left 20px
  .page-name
    position absolute
    white-space nowrap
    overflow hidden
    text-overflow ellipsis
    left 35px
  ul
    background-color lightGrey
  li
    font-family "ingra-condensed-2"
    text-transform uppercase
    font-weight 400
    width 100%
    border-top 1px solid white
    border-bottom 1px solid white
  li, .house-link
    font-size 1.3em
    color black
    height liBoxHeight
    line-height liBoxHeight
    a
      color black
      display block
  li
    i
      font-size 0.8em
      right -1px
  li.done
    background darkGrey
    color white
  li.current-page
    color white
    background lightBlue
    i
      display none
  .house-link, .close-btn
    display inline-block
  .house-link
    width 50px
    text-align center
    z-index 2
    position relative
    left 0
    .custom-home
      background-image url(/survey/img/home.svg)
  .close-btn
    text-align center
    width 50px
    height 50px
    position absolute
    right 0
    background rodinRed
    line-height 50px
    font-size 2em
    i
      color white
      font-size 1.2em

