.FinishedPage
    width: calc(100%- 50px)
    margin-left 50px
    display: inline-block
    img
      height 30px
      width auto
    .ui.header
      margin-top 0
    header
      border-top 2px solid black
      height 3em
      margin-top 1em
