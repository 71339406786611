.Likert-Explanation
    margin 0 auto 2em auto
    .row
      margin 0 auto
      width: 80%
      display flex
      justify-content space-around
    .column
      flex-basis 20px
      text-align center
      color lightBlue


    .blue-number-box
      background lightBlue
      position relative
      text-align center
      color white
      display block
      width: 20px
      &:after
        position absolute
        color lightBlue
        font-size 0.8em
        top 1.5em
    .blue-number-box.disagree
      &:after
        content 'Disagree'
        left: -1.4em
    .blue-number-box.neutral
      &:after
        content 'Neutral'
        left: -0.7em
    .blue-number-box.agree
      &:after
        content 'Agree'
        left: -0.6em
