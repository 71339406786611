inputContainerSize = 120px
inputWidth = 60
.Percentage
  label
    display block
    margin-bottom 1em
  .input-container.invalid
      border 1px solid lightRed !important
      .percentage-label
        border-right 0
  .input-container
      position relative
      width inputContainerSize
      border: 1px solid rgba(34,36,38,.15)
      border-radius 5px
      input
        width (inputWidth) % !important
        display inline-block
        border 0 !important
      .percentage-label
        display inline-block
        line-height 35px
        width: (100 - inputWidth) %
        text-align center
        border-left: 1px solid rgba(34,36,38,.15)

  .error-message
      font-size 0.75em
      color lightRed
      margin-bottom 3px

  .checkmark.icon
      right -30px
      top 10px